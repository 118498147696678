<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate color="grey lighten-3">
      Webhook {{ number + 1 }}
      <template v-slot:actions>
        <v-btn x-small fab color="success" class="mr-2" @click="configureWebhook">
          <v-icon x-small class="white--text">mdi-pencil</v-icon>
        </v-btn>
        <v-btn x-small fab color="error" class="mr-2" @click="removeWebhook">
          <v-icon x-small class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <custom-dialog :dialog="propInfoDialog" :env-selected="envSelected" :do-action="updateWebhook">
      <v-row>
        <v-col cols="12">
          <v-expansion-panels multiple :value="[0,1]" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>Location</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-switch inset v-model="remote" label="Invoke 3rd Party System"></v-switch>
                <v-text-field
                    v-if="remote"
                    label="URI *"
                    :rules="[() => !!context.uri || 'This field is required!']"
                    v-model="context.uri"
                    counter
                    required
                />
                <v-row v-else>
                  <v-col cols="4">
                    <v-select
                        @change="refreshWebhookLocation"
                        v-model="context.service.name"
                        label="Service Name"
                        prepend-icon="mdi-send"
                        hint="Select which service the webhook should invoke at the end of the step exectution."
                        :items="services"
                        item-text="name"
                        item-value="name"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        type="number"
                        v-model="context.service.version"
                        label="Service Version"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-model="context.service.endpoint"
                        label="Service Endpoint"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Payload</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs>
                  <v-tab>Predefined Values</v-tab>
                  <v-tab>Custom Values</v-tab>
                  <v-tab>Map from incoming Request</v-tab>

                  <v-tab-item>
                    <v-select
                        multiple
                        chips
                        deletable-chips
                        v-model="context.payload.meta"
                        label="Predefined Values"
                        hint="Select the computed values that the webhook should append to its payload."
                        persistent-hint
                        :items="[{id: 'user', label: 'Copy of User Record'}, {id: 'account', label: 'Copy of Organization Record'}]"
                        item-text="label"
                        item-value="id"
                    ></v-select>
                  </v-tab-item>
                  <v-tab-item>
                    <label class="v-label v-label--active theme--light">Custom JSON</label>
                    <v-jsoneditor
                        class="py-2"
                        v-model="context.payload.custom" :plus="true" :options="{mode: 'code'}" height="300px"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <v-tabs vertical>
                      <v-tab>Body</v-tab>
                      <v-tab>Query</v-tab>
                      <v-tab>Headers</v-tab>
                      <v-tab-item>
                        <v-jsoneditor
                            class="py-2"
                            v-model="context.payload.request.body" :plus="true" :options="{mode: 'code'}" height="300px"
                        />
                      </v-tab-item>
                      <v-tab-item>
                        <v-jsoneditor
                            class="py-2"
                            v-model="context.payload.request.query" :plus="true" :options="{mode: 'code'}" height="300px"
                        />
                      </v-tab-item>
                      <v-tab-item>
                        <v-jsoneditor
                            class="py-2"
                            v-model="context.payload.request.headers" :plus="true" :options="{mode: 'code'}" height="300px"
                        />
                      </v-tab-item>
                    </v-tabs>
                  </v-tab-item>
                </v-tabs>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </custom-dialog>
  </v-expansion-panel>
</template>
<script>
import CustomDialog from "@/views/internal/components/custom/dialog"
import VJsoneditor  from "v-jsoneditor";

export default {
  name: 'arwWebhook',

  components: {
    CustomDialog,
    VJsoneditor
  },

  props: {
    envSelected: {
      type: Object
    },
    stage: {
      type: String
    },
    number: {
      type: Number
    },
    context: {
      type: Object
    },
    services: {
      type: Array
    }
  },

  data() {

    return {
      remote: false,
      propInfoDialog: {
        title: `Configuring Webhook ${ this.number + 1 }`,
        trigger: false,
        width: 800,
        data: null,
        actions: [
          {
            method: 'updateWebhook',
            icon: 'content-save',
            color: 'success',
            label: 'Save'
          }
        ]
      }
    }
  },

  created() {
    if ( !this.context.uri ) {
      this.context.uri = '';
    }

    if ( !this.context.service ) {
      this.context.service = {
        name: '',
        version: '0',
        endpoint: '/'
      };
    }

    if ( !this.context.service.name ) {
      this.context.service = {
        name: '',
        version: '0',
        endpoint: '/'
      };
    }

    if ( !this.context.payload ) {
      this.context.payload = {
        meta: [],
        custom: {}
      };
    }

    if(!this.context.payload.request) {
      this.context.payload.request = {};
    }

    if(!this.context.payload.request.body) {
      this.context.payload.request.body = [];
    }

    if(!this.context.payload.request.query) {
      this.context.payload.request.query = [];
    }

    if(!this.context.payload.request.headers) {
      this.context.payload.request.headers = [];
    }

    if(this.context.uri && this.context.uri.trim() !== ''){
      this.remote = true;
    }
  },

  watch: {
    remote: function ( to ) {
      if ( to ) {
        this.context.service = {
          name: '',
          version: '0',
          endpoint: '/'
        };
      }
      else {
        this.context.uri = '';
      }
    }
  },

  methods: {
    refreshWebhookLocation() {
      this.context.service.version = '0';
      this.context.service.endpoint = '/';
    },

    configureWebhook() {
      this.propInfoDialog.data = this._lodash.cloneDeep( this.context );
      this.propInfoDialog.trigger = true;
    },

    updateWebhook() {
      const webhookContext = this._lodash.cloneDeep( this.propInfoDialog.data );
      this.propInfoDialog.data = null;
      this.propInfoDialog.trigger = false;
      if (!this.remote) {
        delete webhookContext.uri;
      }
      else {
        delete webhookContext.service;
      }
      this.$nextTick(()=> {
        this.$forceUpdate();
      });
      this.$emit( 'updateWebhook', this.stage, this.number, webhookContext );
    },

    removeWebhook() {
      if ( confirm( 'Are you sure you want to delete this webhook ?' ) ) {
        this.$emit( 'removeWebhook', this.stage, this.number );
      }
    }
  }
}
</script>