<template>
  <div>
    <v-select
        multiple
        v-model="tenants"
        :label="`${(type) === 'account' ? 'Organization': 'User'} Applications`"
        prepend-icon="mdi-apps"
        :hint="`Select which Applications the ${(type) === 'account' ? 'organization': 'user'} should automatically have access to upon registration.`"
        :items="list"
        item-text="name"
        item-value="code"
        @change="addTenant"
        chips
    >
      <template v-slot:selection="{attrs, item, parent, selected}">
        <!-- HTML that describe how select should render selected items -->
        <v-chip
            v-bind="attrs"
            :input-value="selected"
            color="primary">
          <span>{{ item.name }}</span>
          <v-icon small @click="parent.selectItem(item); removeTenantApp(item);">mdi-close</v-icon>
        </v-chip>
      </template>
    </v-select>
    <v-row v-if="context.tenants && context.tenants.length > 0">
      <v-col cols="6" v-for="(oneTenant, i) in context.tenants" :key="i">
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate color="grey lighten-3">
            Application: {{ oneTenant.code }}
            <template v-slot:actions>
              <v-btn x-small fab color="success" class="mr-2" @click="configureTenantApp(oneTenant, i)">
                <v-icon x-small class="white--text">mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small fab color="error" class="mr-2" @click="removeTenantApp(oneTenant, i)">
                <v-icon x-small class="white--text">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-col>
      <custom-dialog :dialog="propInfoDialog" :env-selected="envSelected" :do-action="updateTenantWebhook">
        <template v-if="propInfoDialog.data && propInfoDialog.data.webhook">
          <v-row>
            <v-col cols="12">
              <v-expansion-panels multiple :value="[0,1, 2]" focusable>

                <v-expansion-panel>
                  <v-expansion-panel-header>Location</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-switch inset v-model="remote" label="Invoke 3rd Party System!"></v-switch>
                    <v-text-field
                        v-if="remote"
                        label="URI *"
                        :rules="[() => !!propInfoDialog.data.webhook.uri || 'This field is required!']"
                        v-model="propInfoDialog.data.webhook.uri"
                        counter
                        required
                    />
                    <v-row v-else>
                      <v-col cols="4">
                        <v-select
                            v-model="propInfoDialog.data.webhook.service.name"
                            label="Service Name"
                            prepend-icon="mdi-send"
                            hint="Select which service the webhook should invoke at the end of the step exectution."
                            :items="services"
                            item-text="name"
                            item-value="name"
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            type="number"
                            v-model="propInfoDialog.data.webhook.service.version"
                            label="Service Version"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="propInfoDialog.data.webhook.service.endpoint"
                            label="Service Endpoint"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Payload</v-expansion-panel-header>
                  <v-expansion-panel-content>

                    <template>
                      <v-tabs>
                        <v-tab>Predefined Values</v-tab>
                        <v-tab>Custom Values</v-tab>
                        <v-tab>Map from incoming Request</v-tab>

                        <v-tab-item>
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="Predefined Values"
                              hint="Select the computed values that the application webhook should append to its payload."
                              persistent-hint
                              item-text="label"
                              item-value="id"
                              :items="[{id: 'user', label: 'Copy of User Record'},{id: 'account', label: 'Copy of Organization Record'},{id: 'tenant', label: 'Copy of Application Config'}]"
                              v-model="propInfoDialog.data.webhook.payload.meta"></v-select>
                        </v-tab-item>
                        <v-tab-item>
                          <label class="v-label v-label--active theme--light">Custom JSON</label>
                          <v-jsoneditor
                              class="py-2"
                              v-model="propInfoDialog.data.webhook.payload.custom" :plus="true"
                              :options="{mode: 'code'}" height="300px"
                          />
                        </v-tab-item>
                        <v-tab-item>
                          <v-tabs vertical>
                            <v-tab>Body</v-tab>
                            <v-tab>Query</v-tab>
                            <v-tab>Headers</v-tab>
                            <v-tab-item>
                              <v-jsoneditor
                                  class="py-2"
                                  v-model="propInfoDialog.data.webhook.payload.request.body" :plus="true"
                                  :options="{mode: 'code'}" height="300px"
                              />
                            </v-tab-item>
                            <v-tab-item>
                              <v-jsoneditor
                                  class="py-2"
                                  v-model="propInfoDialog.data.webhook.payload.request.query" :plus="true"
                                  :options="{mode: 'code'}" height="300px"
                              />
                            </v-tab-item>
                            <v-tab-item>
                              <v-jsoneditor
                                  class="py-2"
                                  v-model="propInfoDialog.data.webhook.payload.request.headers" :plus="true"
                                  :options="{mode: 'code'}" height="300px"
                              />
                            </v-tab-item>
                          </v-tabs>
                        </v-tab-item>
                      </v-tabs>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Response</v-expansion-panel-header>
                  <v-expansion-panel-content>

                    <v-tabs>
                      <v-tab>Validation</v-tab>
                      <v-tab>Mapping</v-tab>

                      <v-tab-item>
                        <v-jsoneditor
                            class="py-2"
                            v-model="propInfoDialog.data.webhook.validation" :plus="true" :options="{mode: 'code'}"
                            height="300px"
                        />
                        <div class="v-messages">Provide a <strong>JSON Schema</strong> to validate the format of the
                          response received,
                          <a href="https://json-schema.org/" target="_blank">learn more.</a></div>
                      </v-tab-item>
                      <v-tab-item>
                        <v-tabs vertical>
                          <v-tab>Common</v-tab>
                          <v-tab>Specific</v-tab>

                          <v-tab-item>
                            <v-jsoneditor
                                class="py-2"
                                v-model="propInfoDialog.data.webhook.response.common" :plus="true"
                                :options="{mode: 'code'}" height="300px"
                            />
                          </v-tab-item>
                          <v-tab-item>
                            <v-jsoneditor
                                class="py-2"
                                v-model="propInfoDialog.data.webhook.response.specific" :plus="true"
                                :options="{mode: 'code'}" height="300px"
                            />
                          </v-tab-item>
                        </v-tabs>
                      </v-tab-item>
                    </v-tabs>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-col>
          </v-row>
        </template>
      </custom-dialog>
    </v-row>
  </div>
</template>

<script>
import CustomDialog from "@/views/internal/components/custom/dialog"
import VJsoneditor from "v-jsoneditor";

export default {
  name: 'Arwtenant',

  components: {
    CustomDialog,
    VJsoneditor
  },

  data() {
    return {
      tenants: [],
      remote: false,
      propInfoDialog: {
        title: `Configuring Application `,
        trigger: false,
        width: 900,
        data: null,
        actions: [
          {
            method: 'updateTenantWebhook',
            icon: 'content-save',
            color: 'success',
            label: 'Save'
          }
        ]
      }
    }
  },

  props: {
    envSelected: {
      type: Object
    },
    type: {
      type: String
    },
    stage: {
      type: String
    },
    services: {
      type: Array
    },
    list: {
      type: Array
    },
    context: {
      type: Object
    }
  },

  created() {
    if (!this.context.tenants) {
      this.context.tenants = [];
    }

    if (this.context.tenants && this.context.tenants.length > 0) {
      this.context.tenants.forEach((oneTenant) => {
        this.tenants.push(oneTenant.code);
      });
    }
  },

  methods: {

    addTenant: function (selectedTenants) {
      //append tenants
      if (this.context.tenants.length < selectedTenants.length) {
        selectedTenants.forEach((oneTenantCode) => {
          let oneTenant = {
            code: oneTenantCode,
            webhook: {
              uri: '',
              service: {
                name: '',
                version: 0,
                endpoint: '/'
              },
              payload: {
                request: {
                  body: [],
                  query: [],
                  headers: []
                },
                custom: {},
                meta: []
              },
              validation: {},
              response: {
                common: [],
                specific: {}
              }
            }
          };

          this.list.forEach((oneTenantItem) => {
            if (oneTenantItem.code === oneTenantCode) {
              oneTenant.id = oneTenantItem.id;
            }
          });

          let found = false;
          this.context.tenants.forEach((contextTenants) => {
            if (oneTenantCode === contextTenants.code) {
              found = true;
            }
          });

          if (!found) {
            this.context.tenants.push(oneTenant)
          }

        });
      }

      //remove
      else {
        for (let i = this.context.tenants.length - 1; i >= 0; i--) {
          if (!selectedTenants.includes(this.context.tenants[i].code)) {
            this.removeTenantApp(this.context.tenants[i], i, true);
          }
        }
      }
    },

    configureTenantApp(oneTenantApp, number) {
      this.propInfoDialog.title = `Configuring Application '${oneTenantApp.code}'`;
      this.propInfoDialog.data = this._lodash.cloneDeep(oneTenantApp);

      if (!this.propInfoDialog.data.webhook) {
        this.propInfoDialog.data.webhook = {};
      }

      if (!this.propInfoDialog.data.webhook.uri) {
        this.propInfoDialog.data.webhook.uri = '';
      } else {
        this.remote = true;
      }

      if (!this.propInfoDialog.data.webhook.service) {
        this.propInfoDialog.data.webhook.service = {
          name: '',
          version: 0,
          endpoint: '/'
        };
      } else {
        this.remote = false;
      }

      if (!this.propInfoDialog.data.webhook.payload) {
        this.propInfoDialog.data.webhook.payload = {};
      }

      if (!this.propInfoDialog.data.webhook.payload.custom) {
        this.propInfoDialog.data.webhook.payload.custom = {};
      }

      if (!this.propInfoDialog.data.webhook.payload.meta) {
        this.propInfoDialog.data.webhook.payload.meta = [];
      }

      if (!this.propInfoDialog.data.webhook.payload.request) {
        this.propInfoDialog.data.webhook.payload.request = {};
      }

      if (!this.propInfoDialog.data.webhook.payload.request.body) {
        this.propInfoDialog.data.webhook.payload.request.body = [];
      }

      if (!this.propInfoDialog.data.webhook.payload.request.query) {
        this.propInfoDialog.data.webhook.payload.request.query = [];
      }

      if (!this.propInfoDialog.data.webhook.payload.request.headers) {
        this.propInfoDialog.data.webhook.payload.request.headers = [];
      }

      if (!this.propInfoDialog.data.webhook.validation) {
        this.propInfoDialog.data.webhook.validation = {};
      }

      if (!this.propInfoDialog.data.webhook.response) {
        this.propInfoDialog.data.webhook.response = {};
      }

      if (!this.propInfoDialog.data.webhook.response.common) {
        this.propInfoDialog.data.webhook.response.common = [];
      }

      if (!this.propInfoDialog.data.webhook.response.specific) {
        this.propInfoDialog.data.webhook.response.specific = {};
      }

      this.propInfoDialog.trigger = true;
    },

    removeTenantApp(oneTenantApp, number, force) {
      const _self = this;
      if (force || confirm(`Are you sure you want to unlink the application ${oneTenantApp.code}`)) {
        if (typeof number === 'number') {
          _self.context.tenants.splice(number, 1);

          for (let i = _self.tenants.length - 1; i >= 0; i--) {
            if (_self.tenants[i] === oneTenantApp.code) {
              _self.tenants.splice(i, 1);
              break;
            }
          }
        } else {
          for (let i = _self.context.tenants.length - 1; i >= 0; i--) {
            if (_self.context.tenants[i].code === oneTenantApp.code) {
              _self.context.tenants.splice(i, 1);
              break;
            }
          }
        }
      }
    },

    updateTenantWebhook(number) {
      this.context.tenants.forEach((oneTenant) => {
        if (oneTenant.code === this.propInfoDialog.data.code) {
          oneTenant.webhook = this._lodash.cloneDeep(this.propInfoDialog.data.webhook);
          if (this.remote) {
            delete oneTenant.webhook.service;
          } else {
            delete oneTenant.webhook.uri;
          }
        }
      });

      this.propInfoDialog.trigger = false;

      this.$nextTick(() => {
        this.$forceUpdate();
        this.$emit('updateTenants', this.type, this.stage, this.context.tenants);
      });
    },
  }
};
</script>
