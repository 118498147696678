<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-cogs"
            :title="baseCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="goToPage({route: 'Settings'})">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name *"
                        :rules="[() => !!entry.name || 'This field is required!']"
                        v-model="entry.name"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-switch label="Locked *"
                              v-model="entry.locked"
                              inset
                              required
                    />
                  </v-col>
                </v-row>
                <v-row v-if="['default','social'].includes(type)">
                  <v-col cols="12">
                    <h3>Value</h3>
                    <v-divider class="mt-2 mb-1"></v-divider>
                    <v-switch
                        v-model="jsonValue"
                        :label="(jsonValue) ? 'JSON': 'Plain Text'"
                        inset
                        required
                    />
                    <v-jsoneditor
                        v-if="jsonValue"
                        class="py-2"
                        v-model="entry.value" :plus="true" :options="{mode: 'code'}" height="300px"
                    />
                    <v-textarea
                        counter
                        no-resize
                        :rules="[() => !!entry.value || 'This field is required!']"
                        v-model="entry.value"
                        v-else>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">

                    <v-expansion-panels
                        focusable
                        multiple
                        v-model="arw.stageIndex"
                    >
                      <v-expansion-panel
                          v-for="(stage,i) in arw.stages" :key="i"
                          :disabled="!arw.enabled[stage]"
                      >
                        <v-row no-gutters>
                          <v-col cols="12"
                                 md="11"
                                 sm="10">
                            <v-expansion-panel-header class="text-capitalize">
                              <b>{{ stage }}</b>
                            </v-expansion-panel-header>
                          </v-col>

                          <v-col cols="12"
                                 md="1"
                                 sm="2" style="padding-left: 12px">

                            <v-tooltip top>
                              <template v-slot:activator="{on, attrs}">
                                <div
                                    v-on="on"
                                    v-bind="attrs"
                                    v-show="(stage !== 'approve') || (stage === 'approve' && (arw.enabled.register || arw.enabled.login3rdParty))"
                                >
                                  <v-switch v-if="!['verify'].includes(stage)"
                                            inset
                                            v-model="arw.enabled[stage]"
                                            @change="checkStage(stage)"
                                  >
                                  </v-switch>
                                </div>
                              </template>
                              <span>Enable</span>
                            </v-tooltip>

                          </v-col>
                        </v-row>

                        <v-expansion-panel-content>
                          <template v-if="arw.enabled[stage]">
                            <v-tabs>
                              <v-tab>Webhook</v-tab>

                              <v-tab
                                  v-if="['register','verify','login3rdParty','approve','resetPassword','changePassword', 'validateEmail'].includes(stage)">
                                Account
                              </v-tab>

                              <v-tab
                                  v-if="['register','verify','login3rdParty','approve','resetPassword','changePassword', 'validateEmail'].includes(stage)">
                                User
                              </v-tab>

                              <v-tab v-if="['verify','login3rdParty','approve'].includes(stage)">Stage Specific</v-tab>

                              <!-- webhook tab -->
                              <v-tab-item>
                                <v-btn style="margin-bottom: 10px;"
                                       class="mt-3"
                                       color="secondary" small @click="addWebhook(stage)">
                                  <v-icon color="white" class="mr-1" small>mdi-plus</v-icon>
                                  Add Webhook
                                </v-btn>
                                <v-row v-if="arw.template[stage]">
                                  <v-col cols="4" v-for="(oneWebhook, i) in arw.template[stage].webhooks" :key="i">
                                    <arw-webhook
                                        :env-selected="envSelected"
                                        :context="oneWebhook"
                                        :number="i"
                                        :services="arw.services"
                                        :stage="stage"
                                        @removeWebhook="removeWebhook"
                                        @updateWebhook="updateWebhook"
                                    ></arw-webhook>
                                  </v-col>
                                  <v-col cols="12"
                                         v-if="!arw.template[stage].webhooks || arw.template[stage].webhooks.length === 0">
                                    <v-alert type="warning" border="left" text dense class="py-1">
                                      No configured Webhooks found for this stage.
                                    </v-alert>
                                  </v-col>
                                </v-row>
                              </v-tab-item>

                              <!-- account tab -->
                              <v-tab-item
                                  v-if="['register','verify','login3rdParty','approve','resetPassword','changePassword', 'validateEmail'].includes(stage)">
                                <v-select
                                    v-if="arw.template[stage] && arw.template[stage].account"
                                    multiple
                                    chips
                                    deletable-chips
                                    v-model="arw.template[stage].account.groups"
                                    label="Account Groups"
                                    prepend-icon="mdi-account-group"
                                    hint="Select which Groups the organization should automatically join upon registration."
                                    persistent-hint
                                    :items="arw.groups"
                                    item-text="name"
                                    item-value="code"
                                ></v-select>

                                <arwtenant
                                    v-if="arw.template[stage] && arw.template[stage].account"
                                    :services="arw.services"
                                    :list="arw.tenants"
                                    :type="'account'"
                                    :stage="stage"
                                    :context="arw.template[stage].account"
                                    :env-selected="envSelected"
                                    @updateTenants="updateTenants"
                                ></arwtenant>

                                <throttling
                                    v-if="arw.template[stage] && arw.template[stage].account"
                                    :env-selected="envSelected"
                                    :strategies="arw.template[stage].account.strategies"
                                    :context="arw.template[stage].account"
                                    :type="'account'"
                                    :stage="stage"
                                    @updateThrottlingStrategies="updateThrottlingStrategies"
                                ></throttling>
                              </v-tab-item>

                              <!-- user tab -->
                              <v-tab-item
                                  v-if="['register','verify','login3rdParty','approve','resetPassword','changePassword', 'validateEmail'].includes(stage)">
                                <v-select
                                    v-if="arw.template[stage] && arw.template[stage].user"
                                    multiple
                                    chips
                                    deletable-chips
                                    v-model="arw.template[stage].user.groups"
                                    label="User Groups"
                                    prepend-icon="mdi-account-group"
                                    hint="Select which Groups the user should automatically join upon registration."
                                    persistent-hint
                                    :items="arw.groups"
                                    item-text="name"
                                    item-value="code"
                                ></v-select>

                                <arwtenant
                                    v-if="arw.template[stage] && arw.template[stage].user"
                                    :services="arw.services"
                                    :list="arw.tenants"
                                    :type="'user'"
                                    :stage="stage"
                                    :context="arw.template[stage].user"
                                    :env-selected="envSelected"
                                    @updateTenants="updateTenants"
                                ></arwtenant>

                                <throttling
                                    v-if="arw.template[stage] && arw.template[stage].user"
                                    :env-selected="envSelected"
                                    :strategies="arw.template[stage].user.strategies"
                                    :context="arw.template[stage].user"
                                    :type="'user'"
                                    :stage="stage"
                                    @updateThrottlingStrategies="updateThrottlingStrategies"
                                ></throttling>
                              </v-tab-item>

                              <!-- specific stage tab -->
                              <v-tab-item v-if="['verify','login3rdParty','approve'].includes(stage)">

                                <template v-if="['verify','login3rdParty'].includes(stage)">
                                  <v-switch
                                      v-if="arw.template[stage]"
                                      label="Auto Login"
                                      v-model="arw.template[stage]['auto-login']"
                                      hint="Turn on if you want the user to be automatically logged after this stage."
                                      inset
                                      required
                                      persistent-hint
                                  />
                                </template>

                                <template v-if="['approve'].includes(stage)">
                                  <v-select
                                      v-if="arw.template[stage]"
                                      multiple
                                      chips
                                      deletable-chips
                                      v-model="arw.template[stage]['authorized-groups']"
                                      label="Authorized Groups"
                                      prepend-icon="mdi-account-group"
                                      hint="Select which Groups are authorized to approve users that use this ARW."
                                      persistent-hint
                                      :items="arw.groups"
                                      item-text="name"
                                      item-value="code"
                                  ></v-select>
                                </template>

                              </v-tab-item>

                            </v-tabs>
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="text-right pr-0 mr-0">
                    <v-btn
                        color="success"
                        v-if="canAccess({route: '/environments/:env/custom-entries', method: 'put'}) || canAccess({route: '/environments/:env/custom-entries/:id', method: 'patch'})"
                        @click="saveCSetting">
                      <v-icon class="mr-1">mdi-content-save</v-icon>
                      {{ this.id && this.id.trim() !== '' ? 'Save' : 'Create' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";

import VJsoneditor from "v-jsoneditor";
import Arwtenant from "@/views/internal/pages/settings/arwTenant";
import ArwWebhook from "@/views/internal/pages/settings/arwWebhook";
import throttling from "@/views/internal/components/custom/throttling";

export default {
  name: 'AddEditCustomEntries',

  mixins: [globalMixins, fieldsMixins],

  components: {
    ArwWebhook,
    Arwtenant,
    throttling,
    VJsoneditor
  },

  props: {
    envSelected: {
      type: Object
    },
    type: {
      type: String
    },
    stage: {
      type: String
    },
    id: {
      type: String
    }
  },

  data() {
    return {
      fieldAccess: {},
      loading: false,
      jsonValue: true,
      entry: {
        name: '',
        locked: false,
        value: {}
      },
      arw: {
        stageIndex: [],
        stages: ['login', 'logout', 'register', 'verify', 'login3rdParty', 'approve',
          'changeEmail', 'validateEmail', 'changePassword', 'forgetPassword', 'resetPassword'],
        groups: [],
        tenants: [],
        services: [],
        enabled: {
          'register': false,
          'login': false,
          'logout': false,
          'verify': false,
          'login3rdParty': false,
          'approve': false,
          'changeEmail': false,
          'validateEmail': false,
          'changePassword': false,
          'resetPassword': false,
          'forgetPassword': false
        },
        template: {
          register: {
            account: {},
            user: {},
            webhooks: []
          },
          verify: {
            "auto-login": false,
            account: {},
            user: {},
            webhooks: []
          },
          login: {
            webhooks: []
          },
          logout: {
            webhooks: []
          },
          login3rdParty: {
            "auto-login": false,
            account: {},
            user: {},
            webhooks: []
          },
          approve: {
            "authorized-groups": [],
            account: {},
            user: {},
            webhooks: []
          },
          changeEmail: {
            webhooks: []
          },
          validateEmail: {
            account: {},
            user: {},
            webhooks: [] // not required
          },
          changePassword: {
            account: {},
            user: {},
            webhooks: [] // not required
          },
          forgetPassword: {
            webhooks: []
          },
          resetPassword: {
            account: {},
            user: {},
            webhooks: [] // not required
          }
        }
      }
    }
  },

  watch: {
    "jsonValue": function (to) {
      if (to === false) {
        this.entry.value = '';
      } else {
        this.entry.value = {};
      }
    }
  },

  computed: {
    baseCardTitle() {
      if (this.id && this.entry.name) {
        return 'Updating Custom Setting ' + this.entry.name;
      } else {
        return 'Creating New Custom Setting';
      }
    }
  },

  async created() {
    const _self = this;
    await _self.getExtra();
    await _self.initialize();

  },

  methods: {
    async initialize() {
      const _self = this;

      if (this.id && this.id.trim() !== '') {
        // edit record
        this.fieldAccess = this.settingsUpdateAccess();
        let apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/custom-entries/${this.id}`,
          method: "get",
        };
        this.getSendData(apiOptions)
            .then((response) => {
              this.entry = response.item;

              if (typeof this.entry.value === 'string') {
                this.jsonValue = false;
              }
              if (_self.type === 'arw') {
                // temp
                if (_self.entry.value.password) {
                  _self.entry.value.forgetPassword = _self._lodash.cloneDeep(_self.entry.value.password);
                  delete _self.entry.value.password;
                }
                if (_self.entry.value.email) {
                  _self.entry.value.changeEmail = _self._lodash.cloneDeep(_self.entry.value.email);
                  delete _self.entry.value.email;
                }
                // temp
                // this.arw.stages
                for (let i in _self.entry.value) {
                  _self.arw.enabled[i] = true;
                  _self.arw.stageIndex.push(_self.arw.stages.indexOf(i));

                  // Set the template value
                  _self.arw.template[i] = _self.entry.value[i];

                  if (!_self.arw.template[i].account) {
                    _self.arw.template[i].account = {};
                  }

                  if (!_self.arw.template[i].account.security) {
                    _self.arw.template[i].account.security = {};
                  }

                  if (!_self.arw.template[i].account.security.throttling) {
                    _self.arw.template[i].account.security.throttling = {};
                  }

                  //fix the security strategies if any
                  if (_self.arw.template[i].account) {
                    _self.arw.template[i].account.strategies = [];
                    for (let j in _self.arw.template[i].account.security.throttling) {
                      if (!['publicAPIStrategy', 'privateAPIStrategy', 'strategies'].includes(j)) {
                        let strategy = _self.arw.template[i].account.security.throttling[j];
                        strategy.name = j;
                        _self.arw.template[i].account.strategies.push(strategy);
                        delete _self.arw.template[i].account.security.throttling[j]
                      }
                    }
                  }

                  if (!_self.arw.template[i].user) {
                    _self.arw.template[i].user = {};
                  }

                  if (!_self.arw.template[i].user.security) {
                    _self.arw.template[i].user.security = {};
                  }

                  if (!_self.arw.template[i].user.security.throttling) {
                    _self.arw.template[i].user.security.throttling = {};
                  }

                  //fix the security strategies if any
                  if (_self.arw.template[i].user) {
                    _self.arw.template[i].user.strategies = [];

                    for (let j in _self.arw.template[i].user.security.throttling) {
                      if (!['publicAPIStrategy', 'privateAPIStrategy', 'strategies'].includes(j)) {
                        let strategy = _self.arw.template[i].user.security.throttling[j];
                        strategy.name = j;
                        _self.arw.template[i].user.strategies.push(strategy);
                        delete _self.arw.template[i].user.security.throttling[j]
                      }
                    }
                  }

                  if (!_self.arw.template[i].webhooks) {
                    _self.arw.template[i].webhooks = [];
                  }
                }

                // set the stage Index

                // update the approve and verify states
                this.checkStage();

                // if a stage is not in the original data, initialize it
                this.arw.stages.forEach((one) => {
                  if (!_self.entry.value[one]) {
                    _self.entry.value[one] = _self.arw.template[one];
                  }
                });

              }
              //wait a bit for the servers list to finish its rendering
              setTimeout(() => {
                _self.loading = false;
              }, 500);
            });
      } else {
        // Add new record
        this.fieldAccess = this.settingsCreateAccess();
        // this.arw.stages
        if (_self.type === 'arw') {
          _self.entry.value = _self.arw.template;
        }
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },

    async getExtra() {
      this.loading = true;
      if (this.type === 'arw') {
        const groups = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/organization/groups`,
          method: "get",
          params: {
            fields: ['code', 'name'],
            pagination: false,
          }
        });
        this.arw.groups = groups.items || [];

        const tenants = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/general/tenants`,
          method: "get",
          params: {
            fields: ['code', 'name'],
            pagination: false,
          }
        });
        this.arw.tenants = tenants.items || [];

        const services = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/services`,
          method: "get",
          params: {
            fields: ['version', 'name'],
            pagination: false,
          }
        });
        this.arw.services = services.items || [];
      }
    },

    removeWebhook(stage, number) {
      const _self = this;
      let webhooks = this._lodash.cloneDeep(this.arw.template[stage].webhooks);
      this.arw.template[stage].webhooks = [];
      webhooks.splice(number, 1);
      this.$nextTick(() => {
        _self.arw.template[stage].webhooks = webhooks;
      });
    },

    addWebhook(stage) {
      if (!this.arw.template[stage].webhooks) {
        this.arw.template[stage].webhooks = [];
      }
      this.arw.template[stage].webhooks.push({
        service: {}
      });

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    updateWebhook(stage, number, context) {
      //added in case webhook context needs to be overridden for some reason
    },

    updateTenants(type, stage, tenants) {
      //added in case tenants context needs to be overridden for some reason
    },

    updateThrottlingStrategies(newList, type, stage) {
      this.arw.template[stage][type].strategies = newList;
    },

    checkBasic(entry) {

      this.clearMessages();
      if (!entry.name || entry.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Name is required!'
        });
        return false;
      }

      if (this.type === 'arw') {
        return true;
      }

      if (!entry.value ||
          (!this.jsonValue && entry.value.trim() === '') ||
          (this.jsonValue && JSON.stringify(entry.value) === '{}')
      ) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Value is required!'
        });
        return false;
      }

      return true;
    },

    normalizeCleanUp(entry) {

      if (entry.value['approve']) {
        if (entry.value['approve'].hasOwnProperty('authorized-groups')) {
          if (entry.value['approve']['authorized-groups'].length === 0) {
            delete entry.value['approve'];
          }
        } else {
          delete entry.value['approve'];
        }
      }

      for (let stage in entry.value) {
        if (this.arw.enabled[stage] === true) {
          if (entry.value[stage].account) {
            if (entry.value[stage].account.strategies) {
              entry.value[stage].account.strategies.forEach((oneStrategy) => {
                entry.value[stage].account.security.throttling[oneStrategy.name] = oneStrategy;
                delete entry.value[stage].account.security.throttling[oneStrategy.name].name
              });
              delete entry.value[stage].account.strategies;
            }

            if (entry.value[stage].account.security && entry.value[stage].account.security.throttling) {
              if (entry.value[stage].account.security.throttling.privateAPIStrategy === "") {
                delete entry.value[stage].account.security.throttling.privateAPIStrategy;
              }
              if (entry.value[stage].account.security.throttling.publicAPIStrategy === "") {
                delete entry.value[stage].account.security.throttling.publicAPIStrategy;
              }

              if (Object.keys(entry.value[stage].account.security.throttling).length === 0) {
                delete entry.value[stage].account.security.throttling;
              }
              if (Object.keys(entry.value[stage].account.security).length === 0) {
                delete entry.value[stage].account.security;
              }
            }

            if (entry.value[stage].account.tenants) {
              entry.value[stage].account.tenants.forEach((tenant) => {
                if (tenant.webhook) {
                  if (tenant.webhook.hasOwnProperty('service')) {
                    if (tenant.webhook.service.hasOwnProperty('name') && tenant.webhook.service.name === '') {
                      delete tenant.webhook.service;
                    }
                  }

                  if (tenant.webhook.hasOwnProperty('uri') && tenant.webhook.uri.trim() === '') {
                    delete tenant.webhook.uri;
                  }

                  if (!tenant.webhook.hasOwnProperty('uri') && !tenant.webhook.hasOwnProperty('service')) {
                    delete tenant.webhook;
                  }
                }
              });

              if (entry.value[stage].account.tenants.length === 0) {
                delete entry.value[stage].account.tenants;
              }

            }

            if (Object.keys(entry.value[stage].account).length === 0) {
              delete entry.value[stage].account;
            }

          }

          if (entry.value[stage].user) {

            if (entry.value[stage].user.strategies) {
              entry.value[stage].user.strategies.forEach((oneStrategy) => {
                entry.value[stage].user.security.throttling[oneStrategy.name] = oneStrategy;
                delete entry.value[stage].user.security.throttling[oneStrategy.name].name
              });
              delete entry.value[stage].user.strategies;
            }

            if (entry.value[stage].user.security && entry.value[stage].user.security.throttling) {
              if (entry.value[stage].user.security.throttling.privateAPIStrategy === "") {
                delete entry.value[stage].user.security.throttling.privateAPIStrategy;
              }
              if (entry.value[stage].user.security.throttling.publicAPIStrategy === "") {
                delete entry.value[stage].user.security.throttling.publicAPIStrategy;
              }

              if (Object.keys(entry.value[stage].user.security.throttling).length === 0) {
                delete entry.value[stage].user.security.throttling;
              }
              if (Object.keys(entry.value[stage].user.security).length === 0) {
                delete entry.value[stage].user.security;
              }
            }

            if (entry.value[stage].user.tenants) {
              entry.value[stage].user.tenants.forEach((tenant) => {
                if (tenant.webhook) {
                  if (tenant.webhook.hasOwnProperty('service')) {
                    if (tenant.webhook.service.hasOwnProperty('name') && tenant.webhook.service.name === '') {
                      delete tenant.webhook.service;
                    }
                  }

                  if (tenant.webhook.hasOwnProperty('uri') && tenant.webhook.uri.trim() === '') {
                    delete tenant.webhook.uri;
                  }

                  if (!tenant.webhook.hasOwnProperty('uri') && !tenant.webhook.hasOwnProperty('service')) {
                    delete tenant.webhook;
                  }
                }
              });

              if (entry.value[stage].user.tenants.length === 0) {
                delete entry.value[stage].user.tenants;
              }
            }

            if (Object.keys(entry.value[stage].user).length === 0) {
              delete entry.value[stage].user;
            }

          }

          if (entry.value[stage].webhooks) {

            if (entry.value[stage].webhooks.length) {

              for (let h = entry.value[stage].webhooks.length - 1; h >= 0; h--) {
                if (entry.value[stage].webhooks[h].uri && entry.value[stage].webhooks[h].uri.trim() !== '') {
                  delete entry.value[stage].webhooks[h].service;
                } else {
                  delete entry.value[stage].webhooks[h].uri;
                }
                if (entry.value[stage].webhooks[h].service) {
                  if (entry.value[stage].webhooks[h].service.hasOwnProperty('name')) {
                    if (entry.value[stage].webhooks[h].service.name.trim() === '') {
                      // clean empty webhook
                      entry.value[stage].webhooks.splice(h, 1);
                    }
                  }
                }
              }

            }

            if (entry.value[stage].webhooks.length === 0) {
              delete entry.value[stage].webhooks;
            }
          }

        } else {
          delete entry.value[stage];
        }

      }

      return entry;
    },

    validateArw(entry) {

      this.clearMessages();
      //arw validation
      /**
       * register + verify or none
       *
       * approve depends on verify or login 3rd party
       */
      if ((entry.value['register'] && !entry.value['verify']) ||
          (!entry.value['register'] && entry.value['verify'])) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'The ARW register and verify stages work together, please make sure to configure both or none!'
        });
        return false;
      }

      if (entry.value['approve'] && !entry.value['verify'] && !entry.value['login3rdParty']) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'The ARW approve stage depends on either verify OR login 3rd Party stages to be configured!'
        });
        return false;
      }

      for (let stage in entry.value) {
        if (Object.keys(entry.value[stage]).length === 0) {
          this.pushMessage({
            type: 'error',
            title: 'Validation Error',
            text: `You cannot have an empty stage '${stage}'`
          });
          return false;
        }

        if (['forgetPassword', 'changePassword', 'changeEmail', 'register'].includes(stage)) {
          if (!entry.value[stage].webhooks || !entry.value[stage].webhooks.length) {
            this.pushMessage({
              type: 'error',
              title: 'Validation Error',
              text: `Stage '${stage}' needs at least one webhook`
            });
            return false;
          }
        }
      }

      if (Object.keys(entry.value).length === 0) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Please configure at least 1 stage in this ARW Custom Entry to proceed!'
        });
        return false;
      }
      return true;
    },

    checkStage(stage) {
      let _self = this;
      if (stage) {
        if (['register', 'login3rdParty'].includes(stage)) {
          if (!_self.arw.enabled['register'] && !_self.arw.enabled['login3rdParty']) {
            _self.arw.enabled['approve'] = false;
          } else {
            // _self.arw.enabled['approve'] = true;
          }
        }
        if (stage === 'register') {
          _self.arw.enabled['verify'] = _self.arw.enabled['register'];
        }
      } else {
        // initialize on edit
        if (!_self.arw.enabled['register'] && !_self.arw.enabled['login3rdParty']) {
          _self.arw.enabled['approve'] = false;
        }
        _self.arw.enabled['verify'] = _self.arw.enabled['register'];
      }

    },

    saveCSetting() {
      if (!this.checkBasic(this.entry)) {
        this.scrollToTop();
        return false;
      }

      let payload = this._lodash.cloneDeep(this.entry);

      if (this.type === 'arw') {
        payload = this.normalizeCleanUp(payload);
      }

      if (!this.validateArw(payload)) {
        this.scrollToTop();
        return false;
      }

      let apiOptions;

      if (this.id && this.id.trim() !== '') {
        // edit mode
        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/custom-entries/${this.id}`,
          method: "patch",
          params: {
            name: payload.name,
            type: this.type,
            value: payload.value,
            locked: payload.locked || false
          }
        };

      } else {
        // add new
        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/custom-entries`,
          method: "put",
          params: {
            name: payload.name,
            type: this.type,
            value: payload.value,
            locked: payload.locked || false
          }
        };
      }

      this.getSendData(apiOptions)
          .then(() => {
            let label = (this.id && this.id.trim() !== '') ? 'updated' : 'created';
            this.pushMessage({
              type: 'success',
              title: `Custom Settings ${label}`,
              text: `Custom Setting ${payload.name} has been ${label}!.`
            });
            this.scrollToTop();

            setTimeout(() => {
              if (this.id && this.id.trim() !== '') {

              } else {
                this.goToPage({route: 'Settings'});
              }
            }, 2000);
          });
    }
  }
}
</script>