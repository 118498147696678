<template>
  <v-card>
    <v-card-title>Throttling
      <help-circle :context="help.throttling"></help-circle>
    </v-card-title>
    <v-divider class="mt-2"></v-divider>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="6">
        <v-select
            :items="throttling.items"
            v-model="context.security.throttling.publicAPIStrategy"
            item-text="name"
            item-value="id"
            label="Public Api Strategy"
            hint="Assign a throttling strategy on public apis (accessible by anyone) to this user."
            persistent-hint
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
            :items="throttling.items"
            v-model="context.security.throttling.privateAPIStrategy"
            item-text="name"
            item-value="id"
            label="Private Api Strategy"
            hint="Assign a throttling strategy on private apis (accessible by only logged in user) to this user."
            persistent-hint
        ></v-select>
      </v-col>

    </v-row>
    <data-grid
        :envSelected="envSelected"
        :grid="throttling"
        @doAction="gridAction"
    >
    </data-grid>
    <custom-dialog :dialog="throttlingDialog" :env-selected="envSelected" :do-action="addEditStrategy">
      <v-row v-if="throttlingDialog.data">
        <v-col cols="12" md="6">
          <v-text-field
              label="Name"
              v-model="throttlingDialog.data.name"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              label="Window"
              type="number"
              min="1000"
              v-model="throttlingDialog.data.window"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              label="Limit"
              min="0"
              type="number"
              v-model="throttlingDialog.data.limit"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              label="Retries"
              min="0"
              type="number"
              v-model="throttlingDialog.data.retries"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              label="Delay"
              min="0"
              type="number"
              v-model="throttlingDialog.data.delay"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              label="Type"
              type="number"
              min="0"
              max="1"
              v-model="throttlingDialog.data.type"
          />
        </v-col>
      </v-row>
    </custom-dialog>
  </v-card>
</template>
<script>
import CustomDialog from "@/views/internal/components/custom/dialog";
import dataGrid     from "@/views/internal/components/custom/dataGrid";
import HelpCircle   from "@/views/internal/components/custom/helpCircle";

export default {
  name: 'throttling',

  components: {
    HelpCircle,
    CustomDialog,
    dataGrid
  },

  data(){
    return {
      help:{
        throttling: {
          right: true,
          text: `Apply a Throttling Strategy`,
          link: 'throttling'
        }
      },
      throttling: {
        //mandatory
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Window',
            value: 'window',
          },
          {
            text: 'Limit',
            value: 'limit',
          },
          {
            text: 'Retries',
            value: 'retries',
          },
          {
            text: 'Delay',
            value: 'delay',
          },
          {
            text: 'Type',
            value: 'type',
          },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        itemID: 'name',
        //optional
        sortingList: [ 'name' ],
        //optional
        headActions: [
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            method: 'addStrategy'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'editStrategy',
            color: 'success',
            icon: 'mdi-pencil'
          },
          {
            method: 'deleteStrategy',
            color: 'error',
            icon: 'mdi-delete'
          },
        ]
      },
      throttlingDialog: {
        title: '',
        trigger: false,
        data: null,
        actions: []
      }
    }
  },

  props: {
    envSelected: {
      type: Object
    },
    strategies: {
      type: Array
    },
    context: {
      type: Object
    },
    stage: {
      type: String
    },
    type: {
      type: String
    }
  },

  computed: {
  },

  created(){
    this.initialize();
  },
  updated() {
  },

  methods: {

    initialize(){

      if(!this.context.security){
        this.context.security={};
      }
      if(!this.context.security.throttling){
        this.context.security.throttling={};
      }
      this.throttling.loading = true;
      if(this.strategies){
        this.throttling.items = [ ...this.strategies ];
      }
      this.throttling.loading = false;
    },

    addStrategy() {
      //modal
      this.throttlingDialog.title = 'Create Throttling Strategy';
      this.throttlingDialog.trigger = true;
      this.throttlingDialog.data = {};
      this.throttlingDialog.actions = [];
      this.throttlingDialog.actions.push( {
                                                   method: 'doAddStrategy',
                                                   icon: 'plus',
                                                   color: 'success',
                                                   label: 'Create'
                                                 } );
    },

    doAddStrategy( value ) {
      this.throttling.items.push( { ...this.throttlingDialog.data } );
      this.throttlingDialog.data = {};
      this.throttlingDialog.trigger = false;
      this.$emit('updateThrottlingStrategies', this.throttling.items, this.type, this.stage);
    },

    editStrategy( item ) {
      //modal
      this.throttlingDialog.title = 'Update Throttling Strategy';
      this.throttlingDialog.trigger = true;
      this.throttlingDialog.data = { ...item };
      this.throttlingDialog.original = { ...item };
      this.throttlingDialog.actions = [];
      this.throttlingDialog.actions.push( {
                                                   method: 'doEditStrategy',
                                                   icon: 'pencil',
                                                   color: 'success',
                                                   label: 'Save'
                                                 } );
    },

    doEditStrategy() {
      for ( let i = this.throttling.items.length - 1; i >= 0; i-- ) {
        if ( this.throttling.items[ i ].name === this.throttlingDialog.original.name ) {
          this.throttling.items.splice( i, 1 );
        }
      }
      this.throttling.items.push( { ...this.throttlingDialog.data } );
      this.throttlingDialog.data = {};
      delete this.throttlingDialog.original;
      this.throttlingDialog.trigger = false;

      this.$emit('updateThrottlingStrategies', this.throttling.items, this.type, this.stage);
    },

    addEditStrategy( value ) {
      this[ value ]();
    },

    deleteStrategy( item ) {

      for ( let i = this.throttling.items.length - 1; i >= 0; i-- ) {
        if ( this.throttling.items[ i ].name === item.name ) {
          this.throttling.items.splice( i, 1 );
        }
      }

      if(this.context.security.throttling){
        if(this.context.security.throttling.publicAPIStrategy === item.name){
          this.context.security.throttling.publicAPIStrategy = '';
        }
        if(this.context.security.throttling.privateAPIStrategy === item.name){
          this.context.security.throttling.privateAPIStrategy = '';
        }
      }

      this.$emit('updateThrottlingStrategies', this.throttling.items, this.type, this.stage);
    },

    //grid action hook
    gridAction( action, item, extra ) {
      if ( item ) {
        this[ action ]( item, extra );
      }
      else {
        this[ action ]( extra );
      }
    },
  }
}
</script>