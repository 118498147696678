/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import globalMixins from "@/mixins/globalMixins";

export default {
	name: 'fieldsMixins',
	
	mixins: [globalMixins],
	
	data() {
		return {
			fieldsAcl: {
				settings: {
					create: {},
					update: {}
				}
			}
		}
	},
	
	components: {},
	
	computed: {},
	
	methods: {
		
		settingsCreateAccess() {
			// let route = '/environments/:env/tenants';
			// let method = 'put';
			
			this.fieldsAcl.settings.create = {
				locked: true
			};
			return this.fieldsAcl.settings.create;
		},
		
		settingsUpdateAccess() {
			// let route = '/environments/:env/custom-entries/:id';
			// let method = 'patch';
			
			this.fieldsAcl.settings.update = {
				locked: true
			};
			
			return this.fieldsAcl.settings.update;
		}
	}
}
